<template>
  <div class="mb-2">
    <vSelect
      class="custom-vue-select"
      :options="options"
      :disabled="disabled ? disabled : false"
      :clearable="clearable ? clearable : false"
      :value="value"
      :reduce="(options) => (reduceKey ? options[reduceKey] : options.id)"
      :selectable="isSelectable"
      label="name"
      @input="setSelected"
      @option:selected="$emit('option:selected', $event)"
      @search:blur="$emit('search-blur')"
      v-bind="$attrs"
      :appendToBody="appendToBody"
    ></vSelect>
    <span v-if="validateState === false" class="text-danger">
      {{ $t("VALIDATION.INVALID", { name: "" }) }}
    </span>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "FormSelect",
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      required: true,
    },
    disabled: Boolean,
    clearable: Boolean,
    value: {},
    reduceKey: String,
    feedback_id: {
      type: String,
      default: "",
    },
    i18n: {
      type: Object,
      default: () => ({}),
    },
    validations: {
      type: Object,
      default: () => ({}),
    },
    validateState: {
      type: Boolean,
      default: null,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  components: {
    vSelect,
  },
  methods: {
    setSelected(value) {
      this.$emit("input", value);
    },
    isSelectable(option) {
      return option.selectable !== false;
    },
  },
};
</script>
